import React, { useState, useEffect } from "react";
import css from "./style.module.scss";

import EnrollmentMasterPage from "./components/enrollment-master-page";
import AdditionalInfo from "./components/enrollment-additional-info";

import EligibiltyQuestions from "./components/enrollment-eligibility-questions";
import Checkout from "./components/enrollment-checkout";

import ConfirmApplication from "./components/enrollment-confirm-application";
import ThankYou from "./components/enrollment-thank-you";

import Lottie from "react-lottie-player";
import lottieJson from "./loading.json";
import { Container, Icon } from "..";

import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";

new Promise((resolve) => setTimeout(resolve, 3000));

const EnrollmentPlatformRaw = ({
  agentMetadata,
  baseUrl,
  data,
  setApplications,
  setTerms,
  setAdditionalInfo,
  updateSpecialRates,
}) => {
  const [loading, setLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    new Promise((resolve) => setTimeout(resolve, 1000)).then(async () => {
      const params = JSON.parse(localStorage.getItem("params"));

      const plans = params.plans ? JSON.parse(params.plans) : [];
      const specialRates = params.specialRates;
      const spouse = params.spouse
        ? JSON.parse(JSON.parse(params.spouse))
        : null;
      const dependents = params.dependents
        ? JSON.parse(JSON.parse(params.dependents))
        : null;
      const monthlyValue = JSON.parse(localStorage.getItem("plans")).reduce(
        (a, b) => {
          return a + b.premium.value;
        },
        0
      );

      const response = await axios({
        method: "get",
        url: `${baseUrl}/v1/enrollment/national-general/${
          params.quote_id
        }?state=${params.state}${JSON.parse(params.plan_ids)
          .map((id) => "&plan_id=" + id)
          .join("")}`,
        headers: {
          "x-apollo-quotes-source": agentMetadata.id,
        },
      });

      const response2 = await axios({
        method: "post",
        url: `${baseUrl}/v1/enrollment/terms/national-general/`,
        data: {
          zip_code: params.zip_code,
          state: params.state,
          effective_date: moment()
            .add(1, "days")
            .toDate()
            .toISOString()
            .split("T")[0],
        },
        headers: {
          "x-apollo-quotes-source": agentMetadata.id,
        },
      });

      const response3 = await axios({
        method: "post",
        url: `${baseUrl}/v1/enrollment/national-general/${params.quote_id}/accurate-quote`,
        data: {
          zip_code: params.zip_code,
          state: params.state,
          effective_date: moment()
            .add(1, "days")
            .toDate()
            .toISOString()
            .split("T")[0],
          ...(response2.data.length > 0
            ? { term_length: response2.data[response2.data.length - 1].id }
            : { term_length: 0 }),
          primary_applicant: {
            gender: params.gender,
            date_of_birth: moment()
              .add(-(Number(params.age) * 366), "days")
              .toDate()
              .toISOString()
              .split("T")[0],
            uses_tobacco: params.uses_tobacco,
            ...(specialRates
              ? {
                  has_prior_coverage:
                    specialRates.priorCoverage == 1 ? true : false,
                  rate_tier:
                    specialRates.prefferedRate == 1 ? "Preferred" : "Standard",
                }
              : {}),
          },
          spouse: spouse
            ? {
                ...spouse,
                date_of_birth: spouse.date_of_birth
                  ? spouse.date_of_birth.toISOString().split("T")[0]
                  : moment()
                      .add(-(Number(spouse.age) * 366), "days")
                      .toDate()
                      .toISOString()
                      .split("T")[0],
                gender: spouse.gender,
                uses_tobacco: spouse.uses_tobacco ? true : false,
                ...(specialRates
                  ? {
                      has_prior_coverage:
                        specialRates.priorCoverage == 1 ? true : false,
                      rate_tier:
                        specialRates.prefferedRate == 1
                          ? "Preferred"
                          : "Standard",
                    }
                  : {}),
              }
            : null,
          dependents: dependents
            ? dependents.map((d) => {
                return {
                  ...d,
                  uses_tobacco: "false",
                  date_of_birth: moment()
                    .add(-(Number(d.age) * 366), "days")
                    .toDate()
                    .toISOString()
                    .split("T")[0],
                  ...(specialRates
                    ? {
                        has_prior_coverage:
                          specialRates.priorCoverage == 1 ? true : false,
                        rate_tier:
                          specialRates.prefferedRate == 1
                            ? "Preferred"
                            : "Standard",
                      }
                    : {}),
                };
              })
            : [],
          plans: JSON.parse(params.plans).map((p) => {
            return {
              plan_key: p.plan_key.value,
              insurance_network: p.insurance_network.value,
              fees_enabled: params.optionalAssociationFeeEnabled
            };
          }),
        },
        headers: {
          "x-apollo-quotes-source": agentMetadata.id,
        },
      });

      const fees = [];
      const feesRaw = response3.data.fees;

      localStorage.setItem("plans", JSON.stringify(response3.data.plans));
      
      if (response3.data.fees && response3.data.fees.ONE_TIME) {
        fees.push({
          label: "One time fees",
          value: response3.data.fees.ONE_TIME.total,
        });
      }

      if (response3.data.fees && response3.data.fees.MONTHLY) {
        fees.push({
          label: "Monthly fees",
          value: response3.data.fees.MONTHLY.total,
        });
      }

      setApplications({
        ...response.data,
        plans,
        spouse,
        dependents,
        monthlyValue,
        fees,
        feesRaw,
        specialRates: params.specialRates
      });
      setTerms(response2.data);
      setAdditionalInfo({
        key: "terms",
        value:
          response2.data.length > 0
            ? response2.data[response2.data.length - 1].id
            : 0,
      });
      setAdditionalInfo({
        key: "effectiveDate",
        value: moment().add(1, "days"),
      });

      updateSpecialRates(specialRates);

      setLoading(false);
    });
  }, []);

  return loading ? (
    <Container
      style={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container>
        <Lottie
          loop
          animationData={lottieJson}
          play
          style={{ width: 300, height: 300 }}
        />
        <Icon fill="#000" icon="enrollment-logo" />
      </Container>
    </Container>
  ) : currentStep < 3 ? (
    <EnrollmentMasterPage
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      data={data}
    >
      {currentStep == 0 && (
        <EligibiltyQuestions
          next={() => {
            setCurrentStep(1);
          }}
        />
      )}
      {currentStep == 1 && (
        <AdditionalInfo
          previous={() => {
            setCurrentStep(0);
          }}
          next={() => {
            setCurrentStep(2);
          }}
        />
      )}

      {currentStep == 2 && (
        <Checkout setCurrentStep={setCurrentStep} next={() => {}} />
      )}
    </EnrollmentMasterPage>
  ) : currentStep == 3 ? (
    <ConfirmApplication setCurrentStep={setCurrentStep} />
  ) : (
    <ThankYou />
  );
};

const mapStateToProps = ({ agentMetadata, baseUrl, enrollment, quoteId }) => {
  return {
    agentMetadata,
    baseUrl,
    quoteId,
    enrollment: { ...enrollment },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTerms: (payload) => {
      dispatch({ type: `SET_TERMS`, payload });
    },
    setApplications: (payload) =>
      dispatch({ type: `SET_APPLICATIONS`, payload }),
    setAdditionalInfo: (payload) =>
      dispatch({ type: `SET_ADDITIONAL_INFO`, payload }),
    updateSpecialRates: (payload) =>
      dispatch({ type: `UPDATE_SPECIAL_RATES`, payload }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnrollmentPlatformRaw);
